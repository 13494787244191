// 引入api
import { fun_GetAction, fun_PostAction } from "@/api";
// 引入加密方法
import "@/utils/security.js";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 抽屉状态
      drawerStatus: false,
      // 抽屉确定按钮loading状态
      loading: false,
      userRoleOptions:[],
      // 查询表单字段
      form: {
        id: null, // id
        name: null, // 用户名称
        password: null, // 用户密码
        realName: null, // 真实名称
        sex: null, // 性别
        address: null, // 地址
        linkphone: null, // 联系电话
        fax: null, // 传真
        email: null, // 电子邮箱
        // organization: null, // 所属机构
        userState: null, // 用户状态
        roleId: null // 角色
      },
      // 表单提交返回的错误消息
      errRules: {
        name: null, // 用户名称
        password: null, // 用户密码
        realName: null, // 真实名称
        sex: null, // 性别
        address: null, // 地址
        linkphone: null, // 联系电话
        fax: null, // 传真
        email: null, // 电子邮箱
        // organization: null, // 所属机构
        userState: null, // 用户状态
        roleId: null // 角色
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "用户名称：",
          value: "name",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入用户名称",
            trigger: "blur"
          }
        },
        {
          label: "用户密码：",
          value: "password",
          type: "password",
          required: true,
          rule: {
            required: true,
            message: "请输入用户密码",
            trigger: "blur"
          }
        },
        {
          label: "真实名称：",
          value: "realName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入真实名称",
            trigger: "blur"
          }
        },
        {
          label: "性别：",
          value: "sex",
          type: "radio",
          required: true,
          rule: {
            required: true,
            message: "请输入真实名称",
            trigger: "blur"
          },
          option: [
            {
              label: "男",
              value: "0"
            },
            {
              label: "女",
              value: "1"
            }
          ]
        },
        {
          label: "地址：",
          value: "address",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入地址",
            trigger: "blur"
          }
        },
        {
          label: "联系电话：",
          value: "linkphone",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入联系电话",
            trigger: "blur"
          }
        },
        {
          label: "传真：",
          value: "fax",
          type: "input"
        },
        {
          label: "电子邮箱：",
          value: "email",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入电子邮箱",
            trigger: "blur"
          }
        },
        // {
        //   label: "所属机构：",
        //   value: "organization",
        //   type: "select",
        //   required: true,
        //   clearable: true,
        //   rule: {
        //     required: true,
        //     message: "请选择所属机构",
        //     trigger: "change"
        //   },
        //   queryParams: {
        //     sqlId: "13f3e366-dbb0-4ea2-a6bf-6b84339ab42c",
        //     dictionaryId: "470c9692-d26c-4068-a638-8d7d966220fc"
        //   }
        // },
        {
          label: "用户状态：",
          value: "userState",
          type: "radio",
          required: true,
          rule: {
            required: true,
            message: "请输入用户状态",
            trigger: "blur"
          },
          option: [
            {
              label: "启用",
              value: "1"
            },
            {
              label: "废止",
              value: "0"
            }
          ]
        },
        {
          label: "角色：",
          value: "roleId",
          type: "slot",
          slotName: "proSelect",
          rule: {
            required: true,
            message: "请选择角色",
            trigger: "change"
          }
        }
      ],
      // 加密key
      key: null
    };
  },

  // 模板编译完成
  mounted() {
    // 获取加密key
    this.fun_GetRSAKey();
    this.fun_GetUserRoleSelect();
  },

  // 自定义方法
  methods: {
    /**
     * 获取详情
     * @author 滕威
     */
    async fun_GetDetails(id) {
      this.form.id = id;
      // 请求，获取结果集
      let res = await fun_GetAction("/user/detail?id=" + id);
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显表单
        this.form = data;
      } else {
        // 请求失败，提示错误信息
        this.$message.error(msg);
      }
    },
    /**
     * 表单提交
     * @author 滕威
     */
    fun_SubmitForm() {
      // 校验
      this.$refs.form.$refs.enInform.validate(valid => {
        // 校验通过
        if (valid) {
          // 打开确定按钮loading状态
          this.loading = true;
          this.form.password = window.RSAUtils.encryptedString(
            this.key,
            this.form.password
          );
          // 发起请求，获取结果集
          fun_PostAction("/user/saveOrUpdate", this.form)
            .then(res => {
              // 解构res.data
              const { result, msg, data } = res.data;
              // 判断请求是否成功
              if (result) {
                // 请求一览查询接口
                this.$parent.fun_GetList();
                // 关闭弹窗
                this.drawerStatus = false;
                // 提示信息
                this.$message.success(msg);
              } else {
                // 如果存在返回数据
                if (data != null) {
                  // 定义rules为一个随机数
                  this.errRules = Math.random();
                  // 执行延迟方法
                  this.$nextTick(() => {
                    // 提示
                    this.$message.error("请按提示修改");
                    // 赋值错误消息
                    this.errRules = data;
                  });
                  this.form.password = window.RSAUtils.decryptedString(
                    this.key,
                    this.form.password
                  );
                } else {
                  // 请求失败，提示信息
                  this.$message.error(msg);
                }
              }
              // 取消确定按钮loading状态
              this.loading = false;
            })
            .catch(() => {
              // 取消确定按钮loading状态
              this.loading = false;
            });
        } else {
          // 校验不通过，返回false，页面给出提示
          return false;
        }
      });
    },
    /**
     * 抽屉关闭时执行
     * @author 滕威
     */
    fun_DrawerClose() {
      // 清空所属机构和角色下拉框
      // 遍历formItem
      this.$refs.form.$refs.formItem.map(e => {
        // 找到所属机构和角色下拉框
        if (e.prop === "organization" || e.prop === "roleId") {
          // 置空
          e.$children[1].value = null;
        }
      });
      // 清除表单验证和数据
      this.$refs.form.$refs.enInform.resetFields();
      // 关闭时，清除勾选
      this.$parent.$refs.list.$refs.multipleTable.clearSelection();
    },
    /**
     * 选择下拉框事件
     * @param {e} 选择的value值
     * @param {val} 下拉框的字段
     * @author 滕威
     */
    fun_GetSelectVal(e, val) {
      // 遍历查询表单对象
      for (const key in this.form) {
        // 判断key和val是否相同
        if (key == val) {
          // 相同则复制给表单
          this.form[key] = e;
        }
      }
    },
    /**
     * 选择下拉框事件
     * @param {e} 选择的value值
     * @param {val} 下拉框的字段
     * @author
     */
    async fun_GetUserRoleSelect() {
      let res = await fun_PostAction("/user/getUserRoleSelect", null);
      const { result, data } = res.data;
      if (result) {
        this.userRoleOptions = data;
      }
    },
    /**
     * 获取加密key接口
     * @author 滕威
     */
    fun_GetRSAKey() {
      // 调用获取加密参数接口
      fun_PostAction("/getRSAKey").then(res => {
        // 解构res.data
        const { result, data } = res.data;
        // 判断请求是否成功
        if (result) {
          // 获取加密key
          this.key = window.RSAUtils.getKeyPair(
            data.exponent,
            "",
            data.modulus
          );
        }
      });
    }
  }
};
